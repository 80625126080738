import Resource from "./resource"
import UserModel from "@/store/models/UserModel"

export default class APIResource extends Resource {

  constructor(){
    super()
  }

  static callAPI(params){
    return this.prototype.post(Object.assign(params, {sessionkey: UserModel.getSessionkey(), rt: "json"}))
  }

}
