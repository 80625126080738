import {createStore} from 'vuex'
import company from './modules/company'
import display from './modules/display'
import site from './modules/site'
import user from './modules/user'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    company,
    display,
    site,
    user
  }
})
