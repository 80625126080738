import store from '@/store/index'

export default class UserModel {
  static login(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "dealer::login"}))
        .then(result => {
          if (result.data.code !== 200) {
            reject(result)
          }
          this.setSessionkey(result.data.data.sessionkey)
          resolve(result.data.data)
        })
        .catch(error => reject(error)))
  }

  static logout(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "website::logout"}))
        .then(result => {
          if (result.data.code !== 200) {
            reject(result)
          }
          resolve()
        })
        .catch(error => reject(error)))
  }

  static revert(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "dealer::revert"}))
        .then(result => {
          if (result.data.code !== 200) {
            reject(result)
          }
          resolve()
        })
        .catch(error => reject(error)))
  }

  static update(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "dealer::updateuser"}))
        .then(result => {
          if (result.data.code !== 200) {
            reject(result)
          }
          resolve()
        })
        .catch(error => reject(error)))
  }

  static updateSession(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {route: "dealer::updatesession"}))
        .then(result => {
          if (result.data.code !== 200) {
            reject(result)
          }
          resolve()
        })
        .catch(error => reject(error)))
  }

  static getSessionkey() {
    return store['getters']['getSessionkey']
  }

  static setSessionkey(payload) {
    return store.dispatch('setSessionkey', payload)
  }
}
