import * as userGetters from './user/getters'
import * as userMutations from './user/mutations'
import * as userActions from './user/actions'

const state = {
  sessionkey: ''
}

const getters = userGetters
const mutations = userMutations
const actions = userActions

export default {
  state,
  getters,
  mutations,
  actions
}
