<template>
  <div class="row justify-content-around">
    <div class="col-6">
      <div :class="alert" role="alert">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alert: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  name: "AlertMessage"
}
</script>
