<template>
  <div class="row mb-1 justify-content-md-center" v-if="loading">
    <div class="d-flex justify-content-center">
      <div id="viewSpinner" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="text-center mb-4" v-if="!loading">
    <img :src="image_source" class="rounded img-fluid" :alt="image_source">
  </div>
  <alert-message :alert="alert_class" :message="alert_message"></alert-message>
  <div class="row justify-content-center" v-if="!loading && login_available">
    <div class="col-sm-5 col-md-6 col-lg-3">
      <div class="card mb-2">
        <div class="card-header bg-primary text-white">Welcome</div>
        <div class="card-body">
          <form class="needs-validation" novalidate @submit.prevent="submitCredentialsForLogin">
            <div class="row">
              <div class="col">
                <input v-model.trim="email" type="text" class="form-control" :class="email_error_class"
                       placeholder="Email address" required autocomplete="username">
                <div class="invalid-feedback">
                  Please enter a valid email.
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <input v-model.trim="password" type="password" class="form-control" :class="password_error_class"
                       placeholder="Password" required autocomplete="current-password">
                <div class="invalid-feedback">
                  Please enter a valid password.
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="d-grid gap-2">
                <button class="btn btn-primary btn-block">Sign in&nbsp;
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="alert alert-danger" role="alert" v-if="is_error">
        {{ error_message }}
      </div>
    </div>
  </div>
</template>
<script>

import AlertMessage from '@/components/AlertMessage'
import SiteModel from '@/store/models/SiteModel'
import UserModel from '@/store/models/UserModel'
import {getBrowser, getEncryptedPassword} from '@/plugins/util'
import {getOffset} from '@/plugins/datetime'

export default {
  data() {
    return {
      alert_class: "",
      alert_message: "",
      email: "",
      email_error_class: "",
      error_message: "",
      image_description: "",
      image_source: "",
      is_error: false,
      is_message: false,
      login_available: false,
      loading: true,
      password: "",
      password_error_class: ""
    }
  },
  components: {AlertMessage},
  mounted() {
    this.getStatus()
  },
  methods: {
    getStatus() {
      SiteModel.getSiteStatus()
          .then(result => {
            if (result.status.status === "operational") {
              this.loading = false
              this.login_available = true
              this.image_source = result.image.filelocation + result.image.filename
              this.image_description = result.image.description
            } else if (result.status.status === "maintenance") {
              this.image_source = result.image.filelocation + result.image.filename
              this.image_description = result.image.description
              this.alert_message = result.status.message
              this.alert_class = "alert alert-danger"
              this.loading = false
              this.is_message = true
            } else {
              this.error_message = "There was an error retrieving the data. Please try again later."
              this.is_error = true
            }
          })
          .catch(() => {
            this.error_message = "There was an error retrieving the data. Please try again later."
            this.is_error = true
          })
    },
    isValidLoginForm() {
      this.email_error_class = this.password_error_class = ""
      if (this.email.length === 0) {
        this.email_error_class = "is-invalid"
      } else if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))) {
        this.email_error_class = "is-invalid"
      }
      if (this.password.length === 0) {
        this.password_error_class = "is-invalid"
      }
      return this.email_error_class.length === 0 && this.password_error_class.length === 0
    },
    login() {
      let browser = getBrowser()
      UserModel.login({
        email: this.email,
        password: getEncryptedPassword(this.password),
        sessionkey: this.email,
        browser: browser.name,
        browserversion: browser.version,
        platform: browser.os,
        browserlocaltime: new Date().toString(),
        localoffset: getOffset()
      })
          .then(() => {
            this.$router.push('/dashboard')
          })
          .catch(() => {
            this.error_message = "We could not match the credentials you provided. Please check them and try again."
            this.is_error = true
          })
    },
    submitCredentialsForLogin() {
      this.is_error = false
      if (this.isValidLoginForm()) {
        SiteModel.getSiteStatus()
            .then(result => {
              if (result.status.status !== "operational") {
                this.image_source = result.image.filelocation + result.image.filename
                this.image_description = result.image.description
                this.alert_message = result.status.message
                this.alert_class = "alert alert-danger"
                this.login_available = this.loading = false
                this.is_message = true
              } else {
                this.login()
              }
            })
            .catch(() => {
              this.error_message = "There was an error retrieving the data. Please try again later."
              this.is_error = true
            })
      }
    }
  }
}
</script>
