import {API_URL, ENDPOINT} from './setup'
import axios from 'axios'

export default class Resource {
  constructor () {
    axios.defaults.headers.baseURL = API_URL
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  convert (params = {}) {
    let form_data = new FormData()
    for (let key in params) {
      form_data.append(key, params[key])
    }
    return form_data
  }

  post (params = {}) {
    return this.request('POST', ENDPOINT, this.convert(params))
  }

  request (method, endpoint, params = {}) {
    let headerEntry = {"Content-Type": 'multipart/form-data'}
    let config = this.defineConfig(method, endpoint, params, headerEntry)
    return this.sendRequest(config)
  }

  defineConfig (method, endpoint, params, header) {
    return {
      url: API_URL + endpoint,
      method: method,
      data: params,
      headers: header
    }
  }

  sendRequest (config) {
    const instance = axios.create()
    return new Promise((resolve, reject) => {
      instance(config)
        .then(response => {
          resolve(response)
        })
        .catch(response => {
          reject(response)
        })
    })
  }
}
