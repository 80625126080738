import * as siteGetters from './site/getters'
import * as siteMutations from './site/mutations'
import * as siteActions from './site/actions'

const state = {
  banner: "",
  status: "operational"
}

const getters = siteGetters
const mutations = siteMutations
const actions = siteActions

export default {
  state,
  getters,
  mutations,
  actions
}
