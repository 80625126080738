import * as companyGetters from './company/getters'
import * as companyMutations from './company/mutations'
import * as companyActions from './company/actions'

const state = {
  list: [],
  selected: {}
}

const getters = companyGetters
const mutations = companyMutations
const actions = companyActions

export default {
  state,
  getters,
  mutations,
  actions
}
