import {sha256} from 'js-sha256'
import {detect} from 'detect-browser'

export const arrayDifference = (arr1, arr2) => {
  arr1.filter(x => !arr2.includes(x))
}

export const arrayIntersection = (arr1, arr2) => {
  return arr1.filter(x => arr2.includes(x))
}

export const convertObjectToArray = (obj) => {
  let x = []
  x.push(obj)
  return x
}

export const generateKey = (len = 12) => {
  let a = "", d = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz023456789-@!"
  for (let c = 0; c < len; c++) {
    a += d.charAt(Math.floor(Math.random() * d.length))
  }
  return a
}

export const isInt = (value) => {
  return !isNaN(value) && value !== null && value.toString().lastIndexOf(".") === -1 && value.toString().lastIndexOf("e") === -1
    && (function (x) {
      return (x | 0) === x;
    })(parseFloat(value))
}

export const intersection = (arrayA, arrayB) => {
  return arrayA.filter(item => arrayB.includes(item))
}

export const isUniqueValueOfArray = (arr, property, value) => {
  let index = arr.findIndex(item => item[property] === value)
  return index === -1
}

export const getEncryptedPassword = (value) => {
  return sha256(value)
}

export const getBrowser = () => {
  return detect()
}
