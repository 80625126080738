import store from '@/store/index'

export default class SiteModel {
  static getRoute(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {
        route: "dealer::getroute"
      }))
        .then(result => {
          resolve(result.data.data)
        })
        .catch(error => reject(error)))
  }

  static getSiteStatus(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {
        route: "website::gethomesetup",
        fields: "status, message"
      }))
        .then(result => {
          resolve(result.data.data)
        })
        .catch(error => reject(error)))
  }
}
